import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import DonationForm from "../components/donation-form"
import Alert from "react-bootstrap/Alert"
import QRCode from "qrcode.react"
import axios from "axios"
import { apiUrl } from "../components/constants"
const TransactionPage = props => {
  const txnId = props.txn.split("=")[1]
  const [txnData, setTxnData] = useState<Object>(undefined)
  const [notFound, setNotFound] = useState<Boolean>(false)
  useEffect(() => {
    axios
      .get(`${apiUrl}/api/donate/transaction/${txnId}`)
      .then(req => {
        setTxnData(req.data)
      })
      .catch(err => {
        setNotFound(true)
      })
  }, [])
  return (
    <Layout>
      <SEO title="THACO | Donate" name="donate" />
      <Header page="donate" />
      <section className="pb-5 text-white" style={{ paddingTop: 150 }}>
        <div className="container" style={{ maxWidth: 800 }}>
          <h2 className="mb-5 text-center">ติดตามสถานะการบริจาค</h2>
          <Alert variant="warning text-center">
            <h6 className="mb-0">
              <span className="material-icons">info</span>{" "}
              กรุณาบันทึกลิงค์นี้ไว้ เพื่อใช้ในการติดตามการจัดส่งของสมนาคุณ
              (ถ้ามี)
            </h6>
          </Alert>
          <Alert className="bg-dark text-white">
            {txnData && (
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <p>
                  <b>หมายเลขการบริจาค</b> {txnId}
                  <br />
                  <b>เวลาที่บริจาค</b>{" "}
                  {new Date(txnData["timestamp"]).toLocaleString()}
                  <br />
                  <b>ชื่อผู้บริจาค</b> {txnData["displayName"]}
                  <br />
                  <b>ยอดโอนเงิน</b> {txnData["amount"]}฿<br />
                  {txnData["isShipping"] === true && (
                    <span>
                      <b>ของสมนาคุณที่สั่ง</b>
                      <ul className="mb-0">
                        {txnData["order"][0] > 0 && (
                          <li>
                            <b>สติ๊กเกอร์ติดโน๊ตบุ๊ค (Light)</b>{" "}
                            {txnData["order"][0]} ชิ้น
                          </li>
                        )}
                        {txnData["order"][1] > 0 && (
                          <li>
                            <b>สติ๊กเกอร์ติดโน๊ตบุ๊ค (Dark)</b>{" "}
                            {txnData["order"][1]} ชิ้น
                          </li>
                        )}
                        {txnData["order"][2] > 0 && (
                          <li>
                            <b>พวงกุญแจ</b> {txnData["order"][2]} ชิ้น
                          </li>
                        )}
                      </ul>
                      <b>สถานะการจัดส่ง</b>{" "}
                      {txnData["tracking"]
                        ? `จัดส่งแล้ว รหัสติดตามพัสดุ ${txnData["tracking"]}`
                        : "รอการจัดส่ง"}
                      <br />
                    </span>
                  )}
                </p>
                <div
                  className="rounded p-2 mb-3 bg-white"
                  style={{ alignSelf: "center" }}
                >
                  <QRCode
                    className="d-block"
                    value={`https://thaco.tech/donate?transaction=${txnId}`}
                  />
                </div>
              </div>
            )}
            {notFound === true && (
              <div className="text-center my-2 w-100">
                <h5>ไม่พบข้อมูล</h5>
              </div>
            )}
            {!txnData && notFound === false && (
              <div className="text-center my-2 w-100">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </Alert>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

const Donation = () => {
  <Layout>
    <SEO title="THACO | Donate" name="donate" />
    <Header page="donate" />
    <section className="pb-5 text-white" style={{ paddingTop: 150 }}>
      <div className="container" style={{ maxWidth: 800 }}>
        <h2 className="mb-5 text-center">
          <span className="bg-dark px-2 py-1">ร่วมบริจาค</span>
        </h2>
        <p className="text-center">
          ร่วมเป็นส่วนหนึ่งกับเราในการจัดการแข่งขัน Thailand Computing
          Olympiad 2020 โดยการบริจาคเงินตามจำนวนที่ท่านประสงค์
          ท่านสามารถเลือกรับของสมนาคุณจากเราหากบริจาคถึงจำนวนที่ระบุ
            </p>
        <div className="alert text-center alert-dark">
          เลขบัญชี{" "}
          <b>
            <a
              style={{ border: "none", color: "inherit", opacity: 1 }}
              href="#"
            >
              419-077257-9
                </a>
          </b>
          <br />
              ธนาคารไทยพาณิชย์
              <br />
              ชื่อบัญชี นาย สิรวิชญ์ พงศ์นคินทร์
            </div>
        <DonationForm />
      </div>
    </section>
    <Footer />
  </Layout>
}

const DonationEnded = () => (
  <Layout>
    <SEO title="THACO | Donate" name="donate" />
    <Header page="donate" />
    <section className="pb-5 text-white" style={{ paddingTop: 150 }}>
      <div className="container" style={{ maxWidth: 800 }}>
        <h2 className="mb-5 text-center">
          <span className="bg-dark px-2 py-1">ร่วมบริจาค</span>
        </h2>
        <p className="text-center">
          ร่วมเป็นส่วนหนึ่งกับเราในการจัดการแข่งขัน Thailand Computing
          Olympiad 2020 โดยการบริจาคเงินตามจำนวนที่ท่านประสงค์
          ท่านสามารถเลือกรับของสมนาคุณจากเราหากบริจาคถึงจำนวนที่ระบุ
      </p>
        <div className="alert text-center alert-warning">
          <h5 className='mb-0'>ปิดรับบริจาคแล้ว</h5>
      </div>
        
      </div>
    </section>
    <Footer />
  </Layout>
)

const DonatePage = props => {

  if (props.location.search.split("=")[0] === "?transaction") {
    return <TransactionPage txn={props.location.search} />
  } else {
    return (
      <DonationEnded />
    )
  }
}

export default DonatePage
